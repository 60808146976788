import { ReactNode } from "react";
import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColEnum<T extends DataLike = DataLike> = {
  colType: "enum";
  field: string;
  pin?: "left" | "right";
  headerName: string;
  editable?: boolean;
  dic: { name: string; value: string }[];
  filterable?: boolean;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  validate?: (row: any, input: any) => string;
  multiple?: boolean;
  resolve?: (data: T) => string[];
  valueFormatter?: (({ value }: { value: string }) => string) | undefined;
  renderCell?: (row: T) => ReactNode;
};

export const isColEnum = (col: Col): col is ColEnum => col.colType === "enum";
