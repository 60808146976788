import { LocalizationProvider } from "@/components/contextProviders";
import BasePageLayout from "@/components/layouts/BasePageLayout";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@tailor/datagrid";
import GoogleTagManager from "@tailor/gtm";
import type { AppProps } from "next/app";
import { datadogRum } from "@datadog/browser-rum";
import Head from "next/head";
import theme from "../styles/theme";
import { combineComponents } from "@/lib/helpers/component";
import { Providers } from "@/components/TailorAuthProvider";
import { SessionProvider } from "@/components/SessionProvider";
import { Suspense } from "react";
import { LoadingBackdrop } from "@/components/LoadingBackdrop";
import { usePathname } from "next/navigation";
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE ?? "");

// "if deployed and running on Vercel, initialize Datadog RUM"
if (process.env.NEXT_PUBLIC_ENVIRONMENT) {
  /**
   * sessionSampleRate: 20%
   * sessionReplaySampleRate: 100%
   * https://docs.datadoghq.com/ja/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum
   * https://tailor.atlassian.net/browse/SDH-1619
   */
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID ?? "",
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN ?? "",
    site: "us3.datadoghq.com",
    service: process.env.NEXT_PUBLIC_DATADOG_RUM_SERVICE ?? "",
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    //  version: '1.0.0',
    // TODO: v1だと20にしているが、v2だと一旦100にしている。後ほど調整する
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });
}

export default function SDHApp({ Component, pageProps }: AppProps) {
  // hack to circumvert "Type error: 'Component' cannot be used as a JSX component."
  const pathname = usePathname() || "";
  const isLoginPath = pathname === "/login";
  const AnyComponent = Component as any;
  const exceptPaths = ["/login"];

  return (
    <Providers>
      <ThemeProvider theme={theme}>
        <Head>
          <title>SDH勤怠</title>
          <meta httpEquiv="Content-Language" content="ja" />
          <meta name="google" content="notranslate" />
          <meta name="robots" content="noindex, nofollow" />
        </Head>

        <CssBaseline />
        <GoogleTagManager googleTagManagerId={process.env.NEXT_PUBLIC_GTM_ID} />
        <Suspense fallback={<LoadingBackdrop isOpen={true} />}>
          <SessionProvider exceptPaths={exceptPaths}>
            {isLoginPath ? (
              <>
                {combineComponents(<AnyComponent {...pageProps} />, {
                  component: LocalizationProvider,
                })}
              </>
            ) : (
              <BasePageLayout>
                {combineComponents(<AnyComponent {...pageProps} />, {
                  component: LocalizationProvider,
                })}
              </BasePageLayout>
            )}
          </SessionProvider>
        </Suspense>
      </ThemeProvider>
    </Providers>
  );
}
