import { Config } from "@tailor-platform/auth/core";
import { CognitoStrategy } from "./strategies/cognito";

const config = new Config(
  {
    apiHost: process.env.NEXT_PUBLIC_DOMAIN
      ? process.env.NEXT_PUBLIC_DOMAIN.split("/query")[0]
      : "http://sdh-manager.mini.tailor.tech:8000",
    appHost: process.env.NEXT_PUBLIC_APP_HOST || "http://localhost:3000",
    unauthorizedPath: "/login",
  },
);

// workaround: defaultStrategy is not exported
const defaultStrategy = config.getStrategy("default");

export const authConfig = new Config(
  {
    apiHost: process.env.NEXT_PUBLIC_DOMAIN
      ? process.env.NEXT_PUBLIC_DOMAIN.split("/query")[0]
      : "http://sdh-manager.mini.tailor.tech:8000",
    appHost: process.env.NEXT_PUBLIC_APP_HOST || "http://localhost:3000",
    unauthorizedPath: "/login",
  },
  [defaultStrategy, new CognitoStrategy()],
);
