import { Contract, WorkRecord } from "@/types/generated";
import { useCallback, useEffect, useState } from "react";
import { toUniqueElements } from "@/lib/utils/collection";

const WORK_RECORD_INPUT = "WORK_RECORD_INPUT";
const CONTRACT_CODE_HISTORIES = "CONTRACT_CODE_HISTORIES";
const START_AT_HISTORIES = "START_AT_HISTORIES";
const END_AT_HISTORIES = "END_AT_HISTORIES";
const REST_TIME_HISTORIES = "REST_TIME_HISTORIES";

export const useApplyWorkRecord = () => {
  const [workRecord, setWorkRecord] = useState<WorkRecord | null>(null);
  const [contractHistory, setHistories] = useState<Contract[]>([]);
  const [startedAtHistories, setStartedAtHistories] = useState<string[]>([]);
  const [endedAtHistories, setEndedAtHistories] = useState<string[]>([]);
  const [restTimeHistories, setRestTimeHistories] = useState<string[]>([]);

  useEffect(() => {
    const savedWorkRecord = localStorage.getItem(WORK_RECORD_INPUT);
    const savedCodeHistories = localStorage.getItem(CONTRACT_CODE_HISTORIES);
    const savedStartedAtHistories = localStorage.getItem(START_AT_HISTORIES);
    const savedEndedAtHistories = localStorage.getItem(END_AT_HISTORIES);
    const savedRestTimeHistories = localStorage.getItem(REST_TIME_HISTORIES);
    try {
      if (savedWorkRecord) {
        const w = JSON.parse(savedWorkRecord) as WorkRecord;
        setWorkRecord(w);
      }
      if (savedCodeHistories) {
        const hist = JSON.parse(savedCodeHistories) as Contract[];
        setHistories(hist);
      }
      if (savedStartedAtHistories) {
        const hist = JSON.parse(savedStartedAtHistories) as TailorGqlTime[];
        setStartedAtHistories(hist);
      }
      if (savedEndedAtHistories) {
        const hist = JSON.parse(savedEndedAtHistories) as TailorGqlTime[];
        setEndedAtHistories(hist);
      }
      if (savedRestTimeHistories) {
        const hist = JSON.parse(savedRestTimeHistories) as TailorGqlTime[];
        setRestTimeHistories(hist);
      }
    } catch (e) {
      console.error(e);
      setWorkRecord(null);
    }
  }, [setWorkRecord]);

  const addContractHistory = useCallback(
    (contract: Contract) => {
      if (
        contractHistory.filter((value) => value.code === contract!.code)
          .length == 0
      ) {
        contractHistory.unshift(contract);

        if (contractHistory.length > 3) {
          contractHistory.pop();
        }

        setHistories([...contractHistory]);

        localStorage.setItem(
          CONTRACT_CODE_HISTORIES,
          JSON.stringify(contractHistory),
        );
      }
    },
    [contractHistory, setHistories],
  );

  const deleteContractHistories = (contracts: Contract[]) => {
    const codes = contracts.map((contract) => contract.code);

    const history = contractHistory.filter(
      (value) => !codes.includes(value.code),
    );

    setHistories(history);
    localStorage.setItem(CONTRACT_CODE_HISTORIES, JSON.stringify(history));
  };

  const updateLocalstorageTimeHistories = useCallback(
    (name: string, time: TailorGqlTime, beforeHistories: string[]) => {
      const value = time.replace(/:/, "");
      const histories = toUniqueElements([value, ...beforeHistories]);
      const slicedHistories = histories.slice(0, 3);

      localStorage.setItem(name, JSON.stringify(slicedHistories));

      return slicedHistories;
    },
    [],
  );

  const addStartedAtHistory = useCallback(
    (time: TailorGqlTime, beforeHistories?: string[]) => {
      const histories = updateLocalstorageTimeHistories(
        START_AT_HISTORIES,
        time,
        beforeHistories || startedAtHistories,
      );
      setStartedAtHistories(histories);
    },

    [updateLocalstorageTimeHistories, startedAtHistories],
  );

  const addEndedAtHistory = useCallback(
    (time: TailorGqlTime, beforeHistories?: string[]) => {
      const histories = updateLocalstorageTimeHistories(
        END_AT_HISTORIES,
        time,
        beforeHistories || endedAtHistories,
      );
      setEndedAtHistories(histories);
    },
    [updateLocalstorageTimeHistories, endedAtHistories],
  );

  const addRestTimeHistory = useCallback(
    (time: TailorGqlTime, beforeHistories?: string[]) => {
      const histories = updateLocalstorageTimeHistories(
        REST_TIME_HISTORIES,
        time,
        beforeHistories || restTimeHistories,
      );
      setRestTimeHistories(histories);
    },
    [updateLocalstorageTimeHistories, restTimeHistories],
  );

  const updateWorkRecord = useCallback(
    (workRecord: WorkRecord) => {
      if (workRecord.contract) {
        addContractHistory(workRecord.contract);
      }
      if (workRecord.startedAt) {
        addStartedAtHistory(workRecord.startedAt);
      }
      if (workRecord.endedAt) {
        addEndedAtHistory(workRecord.endedAt);
      }
      localStorage.setItem(WORK_RECORD_INPUT, JSON.stringify(workRecord));
      setWorkRecord(workRecord);
    },
    [setWorkRecord, addContractHistory, addEndedAtHistory, addStartedAtHistory],
  );

  const resetWorkRecord = useCallback(() => {
    localStorage.removeItem(WORK_RECORD_INPUT);
    if (workRecord) {
      setWorkRecord(null);
    }
  }, [workRecord, setWorkRecord]);

  return {
    workRecord,
    updateWorkRecord,
    contractHistory,
    addContractHistory,
    deleteContractHistories,
    startedAtHistories,
    addStartedAtHistory,
    endedAtHistories,
    addEndedAtHistory,
    restTimeHistories,
    addRestTimeHistory,
    resetWorkRecord,
    updateLocalstorageTimeHistories,
  };
};
