import { ReactNode } from "react";
import { GridColType } from "@mui/x-data-grid-pro";
import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColCustom<T extends DataLike = DataLike> = {
  colType: "custom";
  field: string;
  headerName: string;
  type?: GridColType;
  link?: string[];
  pin?: "right" | "left";
  sortable?: boolean;
  filterable?: boolean;
  hide?: boolean;
  width?: number;
  renderCell: (row: T) => ReactNode;
  resolve?: (data: T) => string;
};

export const isColCustom = (col: Col): col is ColCustom =>
  col.colType === "custom";
