import { generateSecretHash } from "@/lib/utils/auth";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  InitiateAuthRequest,
} from "@aws-sdk/client-cognito-identity-provider";
import {
  AbstractStrategy,
  Config,
} from "@tailor-platform/auth/core";
import {
  cognitoParamsError,
  cognitoTokenError,
  cognitoTokenResError,
} from "./abstract";

type Options = {
  id: string;
  password: string;
  redirectPath: string;
};

// 環境変数からCognitoの設定を取得
const client_id = process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID || "";
const client_secret_key = process.env.NEXT_PUBLIC_COGNITO_CLIENT_SECRET || "";

// Cognitoクライアントの初期化
const cognitoClient = new CognitoIdentityProviderClient({
  region: "ap-northeast-1",
});

export class CognitoStrategy implements AbstractStrategy<Options> {
  name() {
    return "cognito";
  }

  async authenticate(_config: Config, options: Options) {
    const { id, password } = options;
    // ハッシュ生成
    const secret_hash = generateSecretHash(id, client_id, client_secret_key);

    const input: InitiateAuthRequest = {
      AuthFlow: "USER_PASSWORD_AUTH",
      AuthParameters: {
        USERNAME: id,
        PASSWORD: password,
        SECRET_HASH: secret_hash,
      },
      ClientId: client_id,
    };
    const command = new InitiateAuthCommand(input);

    // cognitoにリクエストを送信してid_tokenを取得
    const res = await cognitoClient.send(command);

    if (
      !res ||
      !res.$metadata ||
      typeof res.$metadata.httpStatusCode !== "number"
    ) {
      throw cognitoTokenResError();
    }
    if (
      res.$metadata.httpStatusCode < 200 ||
      res.$metadata.httpStatusCode > 300
    ) {
      throw cognitoTokenError(res.$metadata.httpStatusCode);
    }

    const id_token = res.AuthenticationResult?.IdToken ?? "";
    return {
      mode: "manual-callback" as const,
      payload: {
        id_token: id_token,
        redirect_path: options.redirectPath,
      },
    };
  }

  callback(config: Config, request: Request) {
    const params = new URL(request.url).searchParams;
    const idToken = params.get("id_token");
    const redirectURI = params.get("redirect_path");
    if (!idToken || !redirectURI) {
      throw cognitoParamsError();
    }

    const payload = new FormData();
    payload.append("grant_type", "urn:ietf:params:oauth:grant-type:jwt-bearer");
    payload.append("assertion", idToken);
    return {
      payload,
      redirectUri: redirectURI,
    };
  }
}
