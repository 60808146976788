import { useSession } from "@tailor-platform/auth/client";
import { usePathname } from "next/navigation";

export const SessionProvider = (
  props: React.PropsWithChildren<{ exceptPaths: Array<string> }>,
) => {
  const pathName = usePathname();
  // queryは除外する
  const cleanPathName = pathName ? pathName.split("?")[0] : "";

  useSession({
    required: !props.exceptPaths.includes(cleanPathName),
  });

  return <>{props.children}</>;
};
