import { TailorAuthProvider, useAuth } from "@tailor-platform/auth/client";
import { authConfig } from "./authConfig";
import dynamic from "next/dynamic";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";
import { authenticatedHttpLink } from "@tailor-platform/auth-apollo";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

const ApolloProvider = dynamic(
  () => import("@apollo/client").then((modules) => modules.ApolloProvider),
  { ssr: false },
);

const client = new ApolloClient({
  link: authenticatedHttpLink(authConfig),
  cache: new InMemoryCache(),
});

export const Providers = ({ children }: { children: ReactNode }) => (
  <TailorAuthProvider config={authConfig}>
    <ErrorBoundary FallbackComponent={RootError}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ErrorBoundary>
  </TailorAuthProvider>
);

// ログイン前など個別のページ単位ではないグローバルなエラーの場合に表示されるコンポーネント
const RootError = ({}: { error: Error }) => {
  const { logout } = useAuth();

  return (
    <Box p={3}>
      <Box>
        <Typography>
          再度ログインしなおしてから処理を続行してください
        </Typography>
      </Box>
      <Button onClick={() => logout({ redirectPath: "/login" })}>
        ログアウト
      </Button>
    </Box>
  );
};
