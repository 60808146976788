import { HmacSHA256, enc } from "crypto-js";

/**
 * cognitoのsecret_hashを生成する
 * @param username cognitoのusername
 * @param clientId cognitoのclientId
 * @param clientSecret cognitoのclientSecret
 * @returns secret_hash
 */
export const generateSecretHash = (
  username: string,
  clientId: string,
  clientSecret: string,
): string => {
  const hash = HmacSHA256(username + clientId, clientSecret);
  return enc.Base64.stringify(hash);
};
