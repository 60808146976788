import { AppUserRoles } from "@/types/enums";
import { CurrentUser } from "@/types/types";

export const isApprover = (user?: CurrentUser): boolean =>
  user?.roles?.findIndex((r) => {
    return (
      r?.name === AppUserRoles.SalesOfficeManager ||
      r?.name === AppUserRoles.AreaManager ||
      r?.name === AppUserRoles.SiteManager ||
      r?.name === AppUserRoles.Clerk
    );
  }) !== -1 || false;

export const isManager = (user?: CurrentUser): boolean =>
  // Managerの判定ロジックはV1の以下の実装を参照
  // (ref: https://github.com/tailor-inc/sdh-kintai-bff/blob/ff2a8f5d499d8debefc1a953fd937cd03fd1496c/src/authorize/auth.go)
  user?.roles?.findIndex((r) => {
    return (
      r?.name === AppUserRoles.SalesOfficeManager ||
      r?.name === AppUserRoles.AreaManager ||
      r?.name === AppUserRoles.Clerk
    );
  }) !== -1 || false;

export const isSiteManager = (user?: CurrentUser): boolean =>
  user?.roles?.findIndex((r) => {
    return r?.name === AppUserRoles.SiteManager;
  }) !== -1 || false;

export const isStaff = (user?: CurrentUser): boolean =>
  user?.roles?.findIndex((r) => r?.name === AppUserRoles.Staff) !== -1 || false;
