import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";

type ConfirmDialogProps = {
  warningTitle?: string;
  title: string;
  confirmButtonText: string;
  confirmButtonType?: string;
  cancelButtonText?: string;
  contentText?: string;
  contentTexts?: string[];
  open: boolean;
  disableComfirmButton?: boolean;
  onCloseDialog: () => void;
  onConfirmDialog: () => void;
  onCancelDialog?: () => void;
  children?: React.ReactNode;
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    warningTitle,
    title,
    open,
    confirmButtonText,
    cancelButtonText,
    contentText,
    contentTexts,
    confirmButtonType,
    disableComfirmButton,
    onCloseDialog,
    onConfirmDialog,
    onCancelDialog,
    children,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "840px",
          },
        },
      }}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        {warningTitle === undefined ? (
          <DialogTitle variant="h3" sx={{ p: 0 }}>
            {title}
          </DialogTitle>
        ) : (
          <Stack>
            <DialogTitle sx={{ p: 0 }} style={{ fontWeight: "700" }}>
              <span
                style={{
                  color: "#D32F2F",
                  fontSize: "32px",
                  lineHeight: "38.4px",
                }}
              >
                {warningTitle}
              </span>
              <span
                style={{
                  color: "#3D4454",
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
              >
                {title}
              </span>
            </DialogTitle>
          </Stack>
        )}
        <Divider />
      </Stack>
      <DialogContent sx={{ px: 2, py: 0 }}>
        {contentTexts?.length ? (
          contentTexts.map((text, index) => (
            <DialogContentText key={index}>{text}</DialogContentText>
          ))
        ) : (
          <DialogContentText sx={{ mb: 2 }}>
            {contentText || "この操作は取り消せません。"}
          </DialogContentText>
        )}
        {!!children && <Box>{children}</Box>}
      </DialogContent>
      <DialogActions sx={{ py: 3, px: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
          gap={2}
        >
          <Button
            size="medium"
            variant="outlined"
            onClick={onCancelDialog ? onCancelDialog : onCloseDialog}
            disableElevation
            disabled={disableComfirmButton}
          >
            {cancelButtonText || "キャンセル"}
          </Button>
          <Button
            size="medium"
            variant={
              confirmButtonType === "warning" ? "contained" : "borderRed"
            }
            onClick={onConfirmDialog}
            autoFocus
            disableElevation
            disabled={disableComfirmButton}
          >
            {confirmButtonText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
