import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  GridCellParams,
  GridColType,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import clsx from "clsx";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import { CellString } from "../Cell/CellString";
import { CellStringArray } from "../Cell/CellStringArray";
import { ColEnum } from "../Col/ColEnum";
import { EditCellEnum } from "../EditCell/EditCellEnum";
import { EditCellEnumMultiple } from "../EditCell/EditCellEnumMultiple";

type ColEnumDefResult = {
  field: string;
  headerName: string;
  filterable?: boolean;
  editable?: boolean;
  sortable?: boolean;
  width?: number;
  type: GridColType;
  valueFormatter?: (({ value }: { value: string }) => string) | undefined;
  cellClassName: (params: GridCellParams) => string;
  filterOperators: GridFilterOperator[];
  renderCell: <T extends string[] | string>(
    params: CustomGridRenderCellParams<T>,
  ) => JSX.Element;
  renderEditCell: <T extends string[] | string>(
    params: CustomGridRenderEditCellParams<T>,
  ) => JSX.Element;
};
export const ColEnumDef = (colProp: {
  col: ColEnum;
  externalRowBooleanState: (rowId: number | string) => {
    [key: string]: boolean;
  };
  updateExternalRowBooleanState: (
    rowId: number | string,
  ) => (key: string) => (value: boolean) => void;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
}): ColEnumDefResult => {
  const EnumInputValue = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const handleFilterChange = (event: SelectChangeEvent<string>) => {
      if ("value" in event.target) {
        applyValue({ ...item, value: event.target.value });
      }
    };

    return (
      <FormControl fullWidth sx={{ height: "63px" }}>
        <InputLabel
          id="enum-filter-select-label"
          sx={{ top: "8px", left: "-15px" }}
        >
          {colProp.col.headerName}
        </InputLabel>
        <Select
          sx={{ paddingLeft: "0px" }}
          labelId="enum-filter-select-label"
          id="demo-simple-select"
          label={colProp.col.headerName}
          defaultValue={item.value ?? "undefined"}
          onChange={handleFilterChange}
          variant="standard"
        >
          {colProp.col.dic.map((e) => (
            <MenuItem value={e.value} key={e.value}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const equalOperator: GridFilterOperator = {
    label: "に等しい",
    value: "equal",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      //not need to implement because of server side filtering
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value === filterItem.value;
      };
    },
    InputComponent: EnumInputValue,
  };
  const notEqualOperator: GridFilterOperator = {
    label: "に等しくない",
    value: "notEqual",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value !== filterItem.value;
      };
    },
    InputComponent: EnumInputValue,
  };

  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    filterable: colProp.col.filterable,
    editable: colProp.col.editable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    type: "string",
    valueFormatter: colProp.col.valueFormatter,
    renderCell: <T extends string[] | string>(
      params: CustomGridRenderCellParams<T>,
    ): JSX.Element => {
      if (colProp.col.renderCell) {
        return colProp.col.renderCell(params.row) as JSX.Element;
      }
      if (colProp.col.multiple) {
        return (
          <CellStringArray
            params={params}
            valueFormatter={(values: string[]) =>
              values.map((value) =>
                colProp.col.dic
                  ? colProp.col.dic.find(
                      (u: { value: string; name: string }) => u.value === value,
                    )?.name ?? ""
                  : value,
              )
            }
          />
        );
      } else {
        const stringParams = params as CustomGridRenderCellParams<string>;
        return (
          <CellString
            params={stringParams}
            valueFormatter={(value: string) =>
              colProp.col.dic
                ? colProp.col.dic.find(
                    (u: { value: string; name: string }) => u.value === value,
                  )?.name ?? ""
                : value
            }
          />
        );
      }
    },
    renderEditCell: <T extends string[] | string>(
      params: CustomGridRenderEditCellParams<T>,
    ) => {
      if (colProp.col.multiple) {
        const multipleParams = params as CustomGridRenderEditCellParams<
          string[]
        >;
        return (
          <EditCellEnumMultiple
            params={multipleParams}
            list={colProp.col.dic}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id,
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            externalStringState={colProp.externalRowStringState(params.id)}
          />
        );
      } else {
        const stringParams = params as CustomGridRenderEditCellParams<string>;
        return (
          <EditCellEnum
            params={stringParams}
            list={colProp.col.dic}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id,
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            externalStringState={colProp.externalRowStringState(params.id)}
          />
        );
      }
    },
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
    filterOperators: [equalOperator, notEqualOperator],
  };
};
