import {
  useFetchGroupsQuery,
  useFetchRolesQuery,
  useMeForProfileSuspenseQuery,
} from "@/graphql/queries.v2.generated";
import { CurrentUser, CurrentUserGroup, CurrentUserRole } from "@/types/types";

export const useCurrentUser = () => {
  const {
    data: meData,
    error: meError,
  } = useMeForProfileSuspenseQuery();

  if (meError) {
    const user: CurrentUser = {};
    return {
      user: user,
      error: meError.message,
    };
  }

  const userRoleIds = meData?.me?.roles || [];
  const userGroupIds = meData?.me?.groups || [];

  const { data: roleData } = useFetchRolesQuery({
    variables: {
      query: {
        id: {
          in: userRoleIds,
        },
      },
      first: 1000,
    },
  });

  const { data: groupData } = useFetchGroupsQuery({
    variables: {
      query: {
        id: {
          in: userGroupIds,
        },
      },
      first: 1000,
    },
  });

  const roles = roleData?.roles?.edges.reduce((acc, { node }) => {
    const role = node;
    if (userRoleIds?.includes(role.id)) {
      acc.push(role);
    }
    return acc;
  }, [] as CurrentUserRole[]);

  const groups = groupData?.groups?.edges.reduce((acc, { node }) => {
    const group = node;
    if (userGroupIds?.includes(group.id)) {
      acc.push(group);
    }
    return acc;
  }, [] as CurrentUserGroup[]);

  // userを元にnewUserを作成
  const newUser: CurrentUser = {
    id: meData?.me?.id,
    username: meData?.me?.username,
    displayName: meData?.me?.displayName ?? "",
    // rolesとしてidとnameを持つ配列を返す
    roles: roles,
    // groupsとしてidとnameとcodeを持つ配列を返す
    groups: groups,
  };

  return {
    user: newUser,
    error: null,
  };
};
