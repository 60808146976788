import {
  Box,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useRouter } from "next/navigation";
import style from "./StaffDrawer.module.scss";
import { buildYearMonth } from "@/lib/utils/date";
import { CurrentUser, TMouseEvent } from "@/types/types";
import { isManager, isStaff } from "@/lib/utils/roles";

type CommonDrawerProps = {
  isOpen: boolean;
  onClose: TMouseEvent;
  menu: string | null;
  login?: CurrentUser;
};

const CommonDrawer = ({ isOpen, onClose, login }: CommonDrawerProps) => {
  const router = useRouter();
  const yearMonth = buildYearMonth();
  const forward = (path: string): TMouseEvent => {
    return (e) => {
      router.push(path);
      onClose(e);
    };
  };
  return (
    <Drawer className={style.managerDrawer} open={isOpen} onClose={onClose}>
      <Box className={style.menulistContainer}>
        <List>
          <ListItemButton
            disabled={!isManager(login)}
            component={Link}
            className="category-item"
            onClick={forward(`/manager/workrecord/${yearMonth}/all`)}
          >
            <ListItemText primary="管理画面" />
          </ListItemButton>
          <ListItemButton
            disabled={!isStaff(login)}
            component={Link}
            className="category-item"
            onClick={forward(`/staff`)}
          >
            <ListItemText primary="スタッフ向け" />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
};

export default CommonDrawer;
