import { Backdrop, CircularProgress } from "@mui/material";
import { FC } from "react";

interface Props {
  isLoading?: boolean;
  isOpen: boolean;
  onClose?: () => void;
}

export const LoadingBackdrop: FC<Props> = (props: Props) => {
  const { isLoading = true, isOpen, onClose } = props;

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      onClick={onClose}
    >
      {isLoading && <CircularProgress color="inherit" />}
    </Backdrop>
  );
};
